@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HelveticaNeueBlack';
  src: url('./fonts/HelveticaNeueBlack.otf') format('otf');
}

@font-face {
  font-family: 'HelveticaNeueBlackLight';
  src: url('./fonts/HelveticaNeueLight.otf') format('otf');
}

.suspense-loader {
  position: relative;
  opacity: 0;
  animation: reveal 200ms linear 150ms;
  animation-fill-mode: forwards;
}
.suspense-loader::before {
  /* the spinner */
  content: "";
  position: absolute;
  top: calc(50% - 0.75em);
  left: calc(50% - 0.75em);
  width: 1.5em;
  height: 1.5em;
  border-top: 3px solid hsl(0, 0%, 100%);
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0turn);
  }
  
  100% {
    transform: rotate(1turn);
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1.0;
  }
}

.scroll-container-v2 {
  width: 100%;
  max-width: 1100px;
  flex: 0 1 auto;
  justify-content: flex-start;
  margin-top: 3%;
  margin-bottom: 0;
  padding-bottom: 0%;
  display: flex;
  overflow: visible;
}

.commercials {
  display: none;
}

.circle {
  clear: none;
  border: 1px solid var(--white);
  opacity: .8;
  text-align: center;
  border-radius: 100%;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  max-width: clamp(10rem, 25vw, 25rem);
  height: clamp(10rem, 25vw, 25rem);
  max-height: 480px;
  margin: auto;
  font-size: clamp(1.4rem, 4vw, 3.4rem);
  text-decoration: none;
  display: flex;
  position: relative;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%
}

.tree-rings {
  width: 18vw;
  height: 18vw;
  position: absolute
}

.tree-rings.film {
  opacity: 0;
  background-image: url(https://cdn.prod.website-files.com/5f5092a06341960dfc1239c9/5f59a9273c234bc5f925dc3b_rings_film.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover
}

.tree-rings.film:hover {
  opacity: 1;
}

.nav-text-1st {
  transform-origin: 0%;
  color: var(--secondary);
  border-radius: 0;
  flex: 0 auto;
  align-self: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: din-condensed,sans-serif;
  font-weight: 300;
  display: inline-block;
  position: relative;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%
}

.nav-text-2nd {
  opacity: 0;
  transform-origin: 0%;
  color: var(--secondary);
  border-radius: 0;
  flex: 0 auto;
  align-self: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: din-condensed,sans-serif;
  font-weight: 300;
  display: inline-block;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%
}

.video-shadow-overlay {
    box-shadow: inset 0 0 100px -50px rgba(0, 0, 0, 0.8), inset 0 0 100px -50px rgba(0, 0, 0, 0.8), inset 0 -200px 61px -43px rgb(0 0 0);
}

.socialmedia-container-mobile {
  width: 100%;
  height: auto;
  grid-column-gap: 4%;
  grid-row-gap: 2%;
  flex-flow: wrap;
  grid-template-rows: auto auto auto auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: stretch;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: visible;

  @media (max-width: 479px) {
    padding-left: 3%;
    grid-column-gap: 10%;
    grid-row-gap: 10%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-bottom: 20px;
    overflow: auto;
  }
}

.down {
  /* width: 17%; */
  background-image: none;
  flex: none;
  justify-content: center;
  margin-top: 0%;
  margin-right: auto;
  padding-bottom: 0%;
  position: relative;
}
.up {
  /* width: 20%; */
  background-image: none;
  flex: none;
  margin-right: auto;
  padding-bottom: 0%;
  position: relative;
  top: auto;
}
.socialmedia-card-01 {
  width: 20%;
  height: auto;
  aspect-ratio: 9 / 16;
  object-fit: cover;
  background-position: 50%;
  background-size: cover;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  margin-right: 0%;
  padding-bottom: 0%;
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;

  ._01 {
    width: 20%;
    flex: none;
    justify-content: center;
    margin-right: auto;
  }
}

.socialmedia-card-01-tech {
  width: 60%;
  height: auto;
  aspect-ratio: 9 / 16;
  object-fit: cover;
  background-position: 50%;
  background-size: cover;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  margin-right: 0%;
  padding-bottom: 0%;
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;

  ._01 {
    width: 20%;
    flex: none;
    /* justify-content: center; */
    margin-right: auto;
  }
}

.socialmedia-card-01:hover {
  transform: translate3d(0px, 0px, 0px) scale3d(1.05, 1.05, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition-duration: 350ms;
}

.long-form-work-item:hover {
  filter: grayscale();
  transform: translate3d(0px, 0px, 0px) scale3d(1.05, 1.05, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition-duration: 350ms;
}

.long-form-home-item:hover {
  /* filter: grayscale(); */
  transform: translate3d(0px, 0px, 0px) scale3d(1.05, 1.05, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition-duration: 350ms;
}

.overlay:hover {
  opacity: 1;
}

.videobg1 {
  z-index: 5;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  position: relative;
}

.w-background-video {
  color: #fff;
  position: relative;
  overflow: hidden;
}

.w-background-video > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
}

:root {
  --floral-white: #fffff7;
  --dark-slate-blue: #474f62;
  --white: white;
  --black: black
}

.div-block-starting-page {
  width: 90%;
  grid-column-gap: 16px;
  grid-row-gap: 15px;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: dense;
  align-content: start;
  justify-content: flex-start;
  align-items: flex-start;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  line-height: 0;
  display: flex;
  position: absolute;
  top: auto;
  bottom: clamp(5%, 20vw, 14%);
  left: 56px;
}

.w-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto
}

.text-starting {
  z-index: 2;
  opacity: .75;
  color: var(--floral-white);
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  /* font-family: Helveticaneue,sans-serif; */
  font-size: 21px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%, 100% {
      transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
      transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
      transform: translateX(5px);
  }
}

.text-starting:hover {
  /* font-style: italic; */
  color: white;
}

.w-inline-block {
  /* max-width: 100%; */
  /* display: inline-block */
}

.link-block {
  text-decoration: none;
}

.text-starting-page {
  text-transform: none;
  font-family: Agaramondpro,sans-serif;
  font-size: 15px;
  font-weight: 400
}

.text-block-commercial {
  /* color: #000; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  margin-bottom: 0%;
  padding-bottom: 1%;
  /* font-family: Open Sans, sans-serif; */
  font-size: 1.3em;
  font-weight: 300;
  display: block;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.text-block-work {
  /* color: #000; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  margin-bottom: 0%;
  padding-bottom: 5%;
  /* font-family: Open Sans, sans-serif; */
  font-size: 1.3em;
  font-weight: 300;
  display: block;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.text-block-work:hover {
  cursor: pointer;
  border-top: 1.5px solid rgba(255, 255, 255, 0.21);
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.21);
}

.long-form-slider {
  height: auto;
  background-color: rgba(221, 221, 221, 0);
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 53px;
  display: flex;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.w-slider {
  height: 300px;
  text-align: center;
  clear: both;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* tap-highlight-color: rgba(0, 0, 0, 0); */
  /* background: #ddd; */
  position: relative;
}

.w-slider-mask {
  z-index: 1;
  /* height: 119%; */
  white-space: nowrap;
  display: block;
  position: relative;
  left: 0;
  right: 0;
  overflow: hidden;
}

.mask-2 {
  width: 90%;
  overflow: hidden;
}

.w-slide {
  vertical-align: top;
  width: 100%;
  height: 100%;
  white-space: normal;
  text-align: left;
  display: inline-block;
  position: relative;
}

.video-bg {
  display: none;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.project-grid {
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  flex-wrap: nowrap;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  padding: 3%;
  display: flex;
  scroll-behavior: smooth;
  /* white-space: nowrap; */
}

.project-grid.no-transition {
  scroll-behavior: auto;
}

.project-grid::-webkit-scrollbar {
  display: none;
}

.work-item-heading {
  /* margin-top: -7%; */
  position: absolute;
  bottom: 18px;
  left: 18px;
  margin-left: 3%;
  margin-bottom: 0%;
  padding-bottom: 0%;
  /* font-family: 'HelveticaNeueBlack'; */
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.4em;
}

.collaborator-name {
  /* font-family: Open Sans, sans-serif; */
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4em;
}

.work-item-roles {
  margin-top: 0%;
  /* font-family: Open Sans, sans-serif; */
  font-size: 1em;
  font-weight: 300;
}

.long-form-work-item {
  width: 50%;
  /* color: #000; */
  /* flex: none; */
  margin-bottom: 0.5vw;
  text-decoration: none;
  position: relative;
}

.long-form-home-item {
  text-decoration: none;
}

.w-inline-block {
  /* max-width: 100%; */
  /* display: inline-block; */
}

.collaborators-container {
  width: 100%;
  max-width: 1100px;
  flex: 0 auto;
  justify-content: flex-start;
  margin-top: 3%;
  margin-bottom: 0;
  padding-bottom: 0%;
  display: flex;
  overflow: visible;
}

.collaborator-detail {
  width: 100%;
  height: auto;
  grid-column-gap: 3%;
  grid-row-gap: 6%;
  flex-flow: wrap;
  grid-template-rows: auto auto auto auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: stretch;
  justify-content: space-around;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: visible;
}

.right-arrow {
  height: 4em;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-flow: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr .25fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  font-size: 2em;
  display: block;
  position: relative;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.w-slider-arrow-right {
  display: flex;
  justify-content: start;
  align-items: self-start;
  width: 40px;
  cursor: pointer;
  color: #fff;
  /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
  /* tap-highlight-color: rgba(0, 0, 0, 0); */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: auto;
  font-size: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 11;
  left: auto;
}

.left-arrow {
  height: 4em;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-flow: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr .25fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  font-size: 2em;
  display: block;
  position: relative;
  top: auto;
  bottom: 0%;
  right: auto;
}

.w-slider-arrow-left {
  display: flex;
  justify-content: end;
  align-items: self-start;
  width: 40px;
  cursor: pointer;
  color: #fff;
  /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
  /* tap-highlight-color: rgba(0, 0, 0, 0); */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: auto;
  font-size: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 11;
}

.logosImageGroup {
  display: grid;
  place-items: center;
  /* max-width: 800px; */
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  padding: calc(clamp(3rem, 0.5rem + 5vmin, 25rem) / 10);
}

.aboutImageGroup {
  width: clamp(60%, 0rem + 160vmin, 60%);
}

.logoMarqueeGroup1 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: clamp(40rem, 40rem + 100vmin, 120rem);
  animation: scrollX 15s linear infinite;
}

.logoMarqueeGroup2 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: clamp(40rem, 40rem + 100vmin, 120rem);
  animation: scrollX 15s linear infinite;
  animation-direction: reverse;
  animation-delay: -3s;
}

.logosMarquee {
  filter: grayscale();
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: clamp(430px, 0rem + 80vw, 1200px);
  /* width: 1200px; */
  user-select: none;
  height: auto;
  /* border: 1px solid red; */
  /* mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 0) 10%,
    hsl(0 0% 0% / 0) 90%,
    hsl(0 0% 0% / 0)
  ); */
}

@keyframes scrollX {
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logoImage {
  background-color: white;
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  padding: 5px 20px;
  box-shadow: rgba(182, 182, 182, 0.2) 0px 2px 8px 0px;
}

.aboutImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
  /* border-radius: 0.5rem; */
  aspect-ratio: 16/9;
  padding: 5px 20px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.bulletpoint {
  cursor: none;
  font-size: 30px;
  text-decoration: none;
  position: absolute
}

@media screen and (min-width: 1920px) {
  .div-block-starting-page {
    grid-column-gap: 16px;
    grid-row-gap: 35px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    /* bottom: 20%; */
  }

  .text-starting {
    width: auto;
    font-size: 45px;
    line-height: 15px;
  }

  .link-block {
    font-size: 25px;
  }

  .text-starting-page {
    font-size: 25px
  }
}

.bulletpoint:active {
  opacity: .4
}


.overlay {
  z-index: 8;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.overlay-work {
  z-index: 8;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.navbar-collection-item {
  margin-bottom: 10px;
  line-height: 10px
}

.navlink_button {
  color: rgba(218,218,218,.6);
  text-align: left;
  cursor: none;
  align-items: baseline;
  margin-top: 0;
  /* margin-bottom: 5px; */
  padding-top: 0;
  text-decoration: none;
  display: flex;
}

.w-inline-block {
  /* max-width: 100%; */
  display: inline-block
}

.bulletpoint {
  margin-left: 0;
  line-height: 10px;
}

.video-nav-mobile {
  display: flex;
  flex-direction: row;
  column-gap: 1.5rem;
  position: relative;
  top: -34px;
  justify-content: center;
}

.player-wrapper {
  position: relative;
  /* Player ratio: 100 / (1280 / 720) */
  padding-top: 56.25%;
  box-shadow: inset -100px 0 100px -50px rgba(0, 0, 0, 0.8), inset 100px 0 100px -50px rgba(0, 0, 0, 0.8), inset 0 -200px 61px -43px rgb(0 0 0);


}

.react-player {
  position: absolute;
  object-fit: cover;

  /* Rounded corners */
  top: 0;
  left: 0;
}

.work-section {
  padding-top: clamp(12rem, 30vw, 18rem);
}

@media screen and (min-width: 1280px) {
  .link-block {
    font-size: 17px;
  }

  .text-starting {
    font-size: 30px;
    line-height: 2px
  }

  .text-starting-page {
    font-size: 18px
  }
  .div-block-starting-page {
    grid-column-gap: 16px;
    grid-row-gap: 35px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
  }
}

@media screen and (min-width: 1440px) {
  .link-block {
    font-size: 19px;
  }

  .text-starting {
    font-size: 35px;
    line-height: 5px
  }

  .text-starting-page {
    font-size: 20px
  }
  .div-block-starting-page {
    grid-column-gap: 16px;
    grid-row-gap: 35px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
  }
}

@media screen and (max-width: 1024px) {
  .div-block-starting-page {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .div-block-starting-page {
    display: none;
    position: absolute;
  }

  .video-shadow-overlay {
    /* top: 50%; */
    box-shadow: inset 0 0;
  }
}

@media screen and (max-width: 767px) {
  .long-form-work-item {
    width: 50%;
  }

  .video-shadow-overlay {
    /* top: 50%; */
    box-shadow: inset 0 0;
  }
  /* .w-slider-arrow-right {
    display: none
  } */
}

@media screen and (max-width: 479px) {
  .long-form-work-item {
    width: 100%;
  }

  .video-shadow-overlay {
    top: 50%;
    box-shadow: inset 0 0;
    /* display: none; */
  }

  .work-section {
    font-size: 12px;
  }

  .w-slider-arrow-right {
    top: 100%;
  }

  .w-slider-arrow-left {
    top: 100%;
  }

  /* .w-slider-arrow-right {
    display: none
  } */

  .nav-lateral {
    display: flex;
    align-items: center;
    height: 80%;
    min-height: 80%;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 0;
    padding-left: 25px;
    position: fixed;
  }

  .navbar-collection-list {
    cursor: none;
    margin-left: -19px;
    line-height: 22px;
  }

  .navbar_project_list {
    /* flex: none, */
    display: block;
    cursor: block;
  }

  .socialmedia-card-01 {
    width: 36%;
  }

  .overlay {
    height: 100%;
    width: 100%;
  }

  .videobg1 {
    width: 100%;
  }

  .socialmedia-card-01:hover {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }

  .player-wrapper {
    position: relative;
    width: 100%;
    top: 100px;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .video-nav-mobile {
    top: 70px;
  }
}

.dark-bg {
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 44, 44, .73);
  border-radius: 16px;
  position: absolute;
}

.video-logo {
  z-index: 1;
  background-position: 50%;
  background-size: cover;
  margin-top: 11%;
  margin-bottom: 5%;
  padding-bottom: 19%;
  position: relative;
}

@layer base {
  body {
    @apply text-white font-primary bg-black overflow-scroll overflow-x-hidden scroll-smooth;
  }
  .h1 {
    @apply text-[54px] lg:text-[70px] font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] mb-2;
  }
  .h2 {
    @apply text-[25px] lg:text-[30px] font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] mb-6;
  }
  .menu-h1 {
    @apply text-[55px] lg:text-[50px] font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] mb-2;
  }

  .section {
    @apply lg:h-screen;
  }
  .btn {
    @apply py-[18px] px-[50px] h-[66px] flex items-center justify-center text-base uppercase font-secondary font-semibold bg-primary text-white;
  }
  .menu-btn {
    @apply py-[18px] px-[18px] h-[20px] flex items-center justify-center text-base uppercase font-secondary font-semibold bg-primary text-white;
  }
}
